import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Thoughts on strategy`}</h2>
    <p>{`I studied the founding stories of Fortune 100s from 1950 to 2020 - with an emphasis on tech/scientific companies - to discover what enabled these companies to grow and endure. `}</p>
    <p>{`This research, combined with my experience running a startup and working at YC, helped me develop a set of fundamental business principles which seem to hold true across decades, industries, and geographies. `}</p>
    <p>{`Bear in mind, this set of principles is most relevant for high growth, high scale technology companies. Tactics are often different for smaller enterprises and lifestyle businesses, which commonly aim to identify and excel at serving a market niche.`}</p>
    <h2>{`Fundamental business principles`}</h2>
    <ol>
      <li parentName="ol">{`Technology serves the business`}</li>
      <li parentName="ol">{`Follow the fastest path downhill`}</li>
      <li parentName="ol">{`Recycle revenues quickly`}</li>
      <li parentName="ol">{`Be operationally disciplined`}</li>
      <li parentName="ol">{`Choose to compete`}</li>
      <li parentName="ol">{`Build a sustained competitive advantage`}</li>
    </ol>
    <h2>{`1) Technology serves the business`}</h2>
    <p>{`Even in technology companies, business need should drive technological development rather than the other way around.`}</p>
    <p>{`Several Fortune 100s started out as distributors or through selling an incrementally innovative product. They used business expansion and profits to invest heavily into new product development or R&D needed for vertical integration, which enabled them to become leading innovators.`}</p>
    <p>{`Longevity and defensibility come from a `}<strong parentName="p">{`sustained`}</strong>{` technology advantage (ie. continued innovations over several years), not just an initial technology advantage. Tech infrastructure is being modified at an increasingly rapid rate, thus initial technology advantages are shorter lived than ever.`}</p>
    <p>{`Sustained technology advantage comes from heavy ongoing investment into R&D (Apple Silicon, Bell Labs, Pfizer mRNA, Microsoft+GPT), which is only possible in businesses that remain healthily profitable for multiple decades.`}</p>
    <p>{`Revenue-funded R&D enables investment in a long term portfolio of novel technologies which is resilient to individual failures. Whereas VC-funded R&D is far less resilient to technical and scientific risk. However, companies become risk-averse as they grow and can’t maintain the innovative culture that attracts top talent. So heavy ongoing R&D investment and outcomes can prove challenging.`}</p>
    <h2>{`2) Follow the fastest path downhill`}</h2>
    <p>{`Similar to a gradient descent function, businesses tend to be best served by chasing the opportunities in front of them that creates the fastest velocity. This is an evolutionary / bottoms up approach to business development rather than intelligent design / top down approach.`}</p>
    <p>{`In practical terms, this means taking advantage of opportunities where:`}</p>
    <ul>
      <li parentName="ul">{`The customer set demonstrates a strong need and willingness to pay`}</li>
      <li parentName="ul">{`The product can be sold at a strong profit, where profits can be reinvested into improving and scaling the product`}</li>
      <li parentName="ul">{`The product can be delivered with low operational complexity, meaning it can be built and scaled quickly`}</li>
    </ul>
    <p>{`The aim is to build strong initial velocity, learn rapidly through iteration, and build a strong cash position. As new opportunities appear, businesses should continue to take horizontal steps (or small pivots) towards whichever opportunity is the fastest path downhill. These steps/pivots need to be taken aggressively, rather than slowly to avoid splitting focus.`}</p>
    <p>{`This is especially critical during dramatic changes in the macro landscape (economic downturns, technological shifts, etc). Businesses that react quickly and fully can take outsized advantage of these shakeups.`}</p>
    <p>{`Founders often get over-strategic about products on day 1, rather than realizing they are capacity building and organization building along the way to winning a market. On the other end, organizations get stuck on the current product / opportunity or don’t make full effort to pivot into new directions due to organizational drag and risk aversion.`}</p>
    <h2>{`3) Recycle revenues quickly`}</h2>
    <p>{`Building on the evolutionary approach to business development, we can draw a parallel between exponential business growth and exponential organism growth.`}</p>
    <p>{`Key factors that impact exponential growth:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Organism growth`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Business equivalent`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource availability`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Capital availability (revenue or funding)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Speed of reproduction (birth rate)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Speed of building and delivering product`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Death rate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer churn`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`To simplify this further, the single metric that is most closely indicative of sustained exponential business growth in the early days is the `}<strong parentName="p">{`recycle rate of revenue`}</strong>{`.`}</p>
    <p>{`In other words, how fast can the business cycle capital into building/delivering more product to generate more capital. The slower the recycle rate (ie. long cycle time), the less likely for the business to grow exponentially. This metric can be applied to businesses across all industries.`}</p>
    <p><strong parentName="p">{`Common factors that slow recycle rate of revenue:`}</strong></p>
    <ul>
      <li parentName="ul">{`Long R&D / product development cycles`}
        <ul parentName="li">
          <li parentName="ul">{`eg. deep tech`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Seasonal or annual businesses`}
        <ul parentName="li">
          <li parentName="ul">{`eg. schools or cohort based businesses`}</li>
        </ul>
      </li>
      <li parentName="ul">{`CapEx heavy businesses`}
        <ul parentName="li">
          <li parentName="ul">{`Usually means a slower speed of product delivery`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Revenue includes financing or delayed payments`}
        <ul parentName="li">
          <li parentName="ul">{`eg. lending businesses or businesses requiring high working capital`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Other factors that limit exponential growth:`}</strong></p>
    <ul>
      <li parentName="ul">{`Over-investment in building and delivering product via funding capital`}
        <ul parentName="li">
          <li parentName="ul">{`ie. revenue does not catch up with spend and growth is unsustainable`}</li>
        </ul>
      </li>
      <li parentName="ul">{`High customer churn`}
        <ul parentName="li">
          <li parentName="ul">{`Growth is unsustainable and collapses`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Industry TAM caps capital availability`}
        <ul parentName="li">
          <li parentName="ul">{`Will create an upper bound and level off growth (top part of the S-curve)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Profitability requires economies of large scale`}
        <ul parentName="li">
          <li parentName="ul">{`eg. insurance companies, utilities, low-margin businesses`}</li>
        </ul>
      </li>
    </ul>
    <p>{`It’s not necessarily possible to avoid all of these limiting factors depending on the business. But measuring and optimizing the recycle rate of revenue can be a helpful framework to increase velocity and focus of the business.`}</p>
    <h2>{`4) Be operationally disciplined`}</h2>
    <p>{`Fundamentally, operational discipline is a way to optimize the recycle rate of revenue. It ensures that company resources (time, money, labor) remain focused on activities that accelerate growth.`}</p>
    <p><strong parentName="p">{`Behaviors of operationally disciplined companies:`}</strong></p>
    <ul>
      <li parentName="ul">{`Maintain focus on core business`}</li>
      <li parentName="ul">{`Ruthless optimization of core functions of the business`}</li>
      <li parentName="ul">{`Hire only when demand outstrips your ability to deliver product`}</li>
      <li parentName="ul">{`Invest in paid marketing only when short term ROI is measurable and positive`}</li>
      <li parentName="ul">{`Maintain smaller headcount and simpler operations to avoid complexity`}</li>
      <li parentName="ul">{`Fewer meetings and flatter organization structures`}</li>
      <li parentName="ul">{`Clear outcomes based goals for all team members`}</li>
    </ul>
    <p><strong parentName="p">{`Behaviors of companies without operational discipline:`}</strong></p>
    <ul>
      <li parentName="ul">{`Launch several products at once in the early days`}</li>
      <li parentName="ul">{`Frequent distractions with new ideas`}</li>
      <li parentName="ul">{`Hiring and product roadmaps based on grand vision rather than customer needs`}</li>
      <li parentName="ul">{`Heavy spend on marketing, BD, conferences, etc`}</li>
      <li parentName="ul">{`Try to compete with incumbents on a full feature set, rather than a smaller niche`}</li>
      <li parentName="ul">{`Starts initiatives because other companies in the space are doing it`}</li>
    </ul>
    <p>{`In many cases, operational discipline will be a competitive advantage against other startups and larger incumbents. It allows companies to deliver a better product for lower cost at a higher margin.`}</p>
    <p>{`The one exception to absolute focus is maintaining investment into R&D. Without this, the business will struggle to adapt to technology shifts. A focusing function for R&D could be:`}</p>
    <ul>
      <li parentName="ul">{`Will the R&D effort improve growth or margins on the core business line?`}</li>
      <li parentName="ul">{`Could the R&D effort build a product that becomes the core business line over time?`}</li>
    </ul>
    <h2>{`5) Choose to compete`}</h2>
    <p>{`Just as most startups fail because the founders give up (or burn out), most businesses stay small because the founders stop trying to make them big.`}</p>
    <p>{`Founders need to truly believe they can (eventually) compete with big incumbents. This comes down to:`}</p>
    <ul>
      <li parentName="ul">{`Believing they and their team are capable of performing at the highest level`}
        <ul parentName="li">
          <li parentName="ul">{`ie. no limiting beliefs or thinking that Fortune 100s are in a different league`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Making the active decision to compete on providing a fundamental service within the industry`}
        <ul parentName="li">
          <li parentName="ul">{`ie. breaking out of the initial niche`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Competing to provide fundamental services requires significant capital and meaningful risk-taking at later stages of the business. This can be hard if the founder has been overworked for years or if the organizational culture has grown less externally competitive.`}</p>
    <p>{`Building this into the culture early helps, and is likely a contributing factor to so many large businesses being started by second time entrepreneurs. It helps to know what you’re getting into and be willing to stay in the fight for a few decades.`}</p>
    <p>{`It also requires a commitment to excellence beyond reason, ignoring advice of experts and investors, and finding clever ways to accomplish seemingly impossible things relative to the competition.`}</p>
    <h2>{`6) Build a sustained competitive advantage`}</h2>
    <p>{`The best research on sustained competitive advantage is from the book `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Why-Moats-Matter-Morningstar-Investing/dp/1118760239"
      }}>{`Why Moats Matter`}</a>{`:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=XziAhR-N7D8"
      }}></a></p>
    <p>{`In summary, there are 5 types of lasting moats:`}</p>
    <ol>
      <li parentName="ol">{`Intangible assets`}
        <ul parentName="li">
          <li parentName="ul">{`Brand, regulatory approvals, or patents`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Cost advantage`}
        <ul parentName="li">
          <li parentName="ul">{`Delivering the product at a lower cost or earning a higher margin`}</li>
        </ul>
      </li>
      <li parentName="ol">{`High switching cost`}
        <ul parentName="li">
          <li parentName="ul">{`The product is entrenched in a consumer or business’ every day tasks, so competitors need to be 10x better to overcome the switching cost`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Network effects`}
        <ul parentName="li">
          <li parentName="ul">{`The value of the product is in the network / marketplace`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Efficient scale`}
        <ul parentName="li">
          <li parentName="ul">{`The business is only profitable because it is a monopoly, so competition would make both businesses unprofitable`}</li>
        </ul>
      </li>
    </ol>
    <p>{`These moats take decades to build and are driven by product and operational excellence, as a byproduct of a strong organizational culture.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      